import React, { Component } from "react";
import socketStore from "../../Stores/socketStore";
import Sockette from "sockette";
import { NotificationManager } from "react-notifications";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateLoginState } from "../../Actions/connStateAction";
import { updateMonitorStats } from "../../Actions/monitorAction";
import { updateConsole, sendConsoleCommand } from "../../Actions/consoleAction";
import { updateChat, sendChat } from "../../Actions/chatAction";
import { updatePlayerList } from "../../Actions/playersAction";

class Sockets extends Component {
  constructor(props) {
    super(props);
    this.CheckConn = this.CheckConn.bind(this);
    this.AttemptLogin = this.AttemptLogin.bind(this);
    this.MonitorMessage = this.MonitorMessage.bind(this);
    this.ConsoleMessage = this.ConsoleMessage.bind(this);
    this.ConnectRestOfChans = this.ConnectRestOfChans.bind(this);
    this.ConsoleCommand = this.ConsoleCommand.bind(this);
    this.ChatSend = this.ChatSend.bind(this);
    this.PlayerListUpdate = this.PlayerListUpdate.bind(this);
  }

  state = {
    lastConsoleCommand: '',
    lastChatSend: '',
  }

  AttemptLogin() {
    socketStore.username = this.props.address;
    socketStore.password = this.props.pass;
    NotificationManager.info('logging in..', 'please wait', 3000);
    this.monitorSocket = socketStore.generate("rconmonitor", {
      timeout: Infinity,
      maxAttempts: 10,
      onopen: e => this.SocketOpened(e),
      onreconnect: e => NotificationManager.warning("reconnecting"),
      onmessage: e => this.MonitorMessage(e),
      onclose: e => this.SockectClosed(e),
      onerror: e => NotificationManager.error("connection refused"),
    });
  }

  //Once logged in, now connect to the rest of the channels
  ConnectRestOfChans() {
    //console
    this.consoleSocket = socketStore.generate("rconconsole", {
      timeout: Infinity,
      maxAttempts: 10,
      onopen: e => this.consoleSocket.send("logfull"),
      onmessage: e => this.ConsoleMessage(e),
    });

    //chat
    this.chatSocket = socketStore.generate("rconchat", {
      timeout: Infinity,
      maxAttempts: 10,
      onopen: e => this.chatSocket.send("chatfull"),
      onmessage: e => this.ChatMessage(e),
    });

    //playerlist:
    this.playerListSocket = socketStore.generate("rconplayerlist", {
      timeout: Infinity,
      maxAttempts: 10,
      onopen: e => this.playerListSocket.send("players"),
      onmessage: e => this.PlayerListUpdate(e),
    });
  }

  //Login and logout:
  SocketOpened(e) {
    this.ConnectRestOfChans();
    this.props.updateLoginState(true);
  }

  SockectClosed(e) {
    console.log("--closed--");
    if (this.props.isLoggedIn) {
      console.log("LOG OUT");
      NotificationManager.warning("closed conn.", "disconnected", 2000);
      this.props.updateLoginState(false);
    }
  }

  //server monitor updates:
  MonitorMessage(e) {
    this.props.updateMonitorStats(e.data);
  }

  //server log updates:
  ConsoleMessage(e) {
    this.props.updateConsole(e.data);
  }

  //when messages are received:
  ChatMessage(e) {
    this.props.updateChat(e.data);
  }

  //sending a remote command:
  ConsoleCommand() {
    if (this.props.consoleCommand != '') {
      this.setState({ lastConsoleCommand: this.props.consoleCommand });
      this.consoleSocket.send(this.props.consoleCommand);
      this.props.sendConsoleCommand('');
    }
  }

  //when sending chat:
  ChatSend() {
    if (this.props.chatSend != '') {
      this.setState({ lastChatSend: this.props.chatSend });
      this.chatSocket.send(this.props.chatSend);
      this.props.sendChat('');
    }
  }

  //check if logged in
  CheckConn() {
    if (!this.props.isLoggedIn && this.props.address != '') {
      this.AttemptLogin();
    }
  }

  //player list changes from server:
  PlayerListUpdate(e) {
    this.props.updatePlayerList(e.data);
  }

  render() {
    this.CheckConn();
    this.ConsoleCommand();
    this.ChatSend();
    return (<div></div>
    );
  }
}

Sockets.propTypes = {
  updateLoginState: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  address: PropTypes.string,
  pass: PropTypes.string
}

const mapPropsToState = state => ({
  address: state.connState.loginUsername,
  pass: state.connState.loginPass,
  isLoggedIn: state.connState.loggedIn,
  consoleCommand: state.chanState.consoleCommand,
  chatSend: state.chanState.chatSend,
  playerList: state.chanState.playerList,
});

export default connect(mapPropsToState, {
  updateLoginState, updateMonitorStats,
  updateConsole, sendConsoleCommand, updateChat, sendChat,
  updatePlayerList
})(Sockets);
