
import React from "react";
import {
  Container,
  Dropdown, Sidebar, Icon, Menu,
} from "semantic-ui-react";

import { Route, Link, Switch } from "react-router-dom";
import RCONconsole from "../Dashboard/console";
import chat from "../Dashboard/chat";
import Monitor from "./Components/monitor";
import PlayerList from "../Dashboard/playerList";

const Dashboard = () => (
  <div>

    <Menu fixed="top" inverted>

      <Menu.Item as="a" header>
         unitystation admin
      </Menu.Item>


      <Monitor />
    </Menu>
    <Sidebar style={{ top: "40px" }} width="thin" as={Menu} animation="uncover" visible icon="labeled" vertical inline inverted textAlign="left">
      <Menu.Item> <Link to="/dashboard/console"><i class="fas fa-chalkboard"></i>&ensp;Console</Link> </Menu.Item>
      <Menu.Item><Link to="/dashboard/chat"><i class="fas fa-broadcast-tower"></i>&ensp;Chat</Link>  </Menu.Item>
      <Menu.Item><Link to="/dashboard/playerlist"><i class="fas fa-user-astronaut"></i>&ensp;Player List</Link>  </Menu.Item>
    </Sidebar>
    <div text style={{ paddingTop: "40px", paddingLeft: "150px" }}>

      <Switch>
        <Route path="/dashboard/console" component={RCONconsole} />
        <Route path="/dashboard/chat" component={chat} />
        <Route path="/dashboard/playerlist" component={PlayerList} />
      </Switch>
    </div>
  </div>
);

export default Dashboard;
