import React, { Component } from "react";
import { connect } from 'react-redux';
import StayScrolled from "react-stay-scrolled";
import { Input } from "semantic-ui-react";
import { updateConsole, sendConsoleCommand } from "../Actions/consoleAction";

class RCONconsole extends Component {
  constructor(props) {
    super(props);
    this.checkOnMessage = this.checkOnMessage.bind(this);
    this.messageList = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.toggleRef = React.createRef();
  }

  state = { 
    messages: [], 
    command: "", 
    lastMsg: '',
    disableAutoScroll: false  
  }

  checkOnMessage() {
    if (this.props.logMsg != this.state.lastMsg) {
      this.setState({
        messages: [...this.state.messages, this.props.logMsg.replace(/<br\s*[\/]?>/gi, "\n")],
        lastMsg: this.props.logMsg
      });
    }
  }

  scrollToBottom() {
    if (this.messageList.current != null && this.toggleRef.current.checked == false) {
      const scrollHeight = this.messageList.current.scrollHeight;
      const height = this.messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  handleChange(event) {
    this.setState({ command: event.target.value });
  }
  handleSubmit(event) {
    this.props.sendConsoleCommand("1" + this.state.command);
    this.setState({ command: "" });
    event.preventDefault();
  }

  componentDidMount() {
    this.setState({
      messages: [this.props.fullLog.replace(/<br\s*[\/]?>/gi, "\n")],
    });

    var disableAutoScrollPref = localStorage.getItem("consoleDisableAutoScroll");
    if(disableAutoScrollPref != null)
    {
      this.toggleRef.current.checked = (disableAutoScrollPref == "true");
      this.setState({disableAutoScroll: (disableAutoScrollPref == "true")});
    }
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  toggleDisableAutoScroll() {
    this.setState({
        disableAutoScroll: this.toggleRef.current.checked
    });

    localStorage.setItem("consoleDisableAutoScroll", this.toggleRef.current.checked);
}

  render() {
    this.checkOnMessage();
    return (
      <div>
        <div
          component="div"
          className="console"
          ref={this.messageList}
        >{this.state.messages}
        </div>
        <form onSubmit={::this.handleSubmit}>
        <Input className="inputfield" type="text" value={this.state.command} onChange={::this.handleChange} />
      </form>
      <div>
        <input type="checkbox" className="disable-autoscroll-checkbox" id="disableAutoScroll" ref={this.toggleRef} placeholder="Disable AutoScroll" onChange={::this.toggleDisableAutoScroll} />
        <label className="disable-autoscroll-checkbox" htmlFor="disableAutoScroll">&nbsp; Disable Autoscroll</label>
      </div>
    </div >

    );
  }
}
const mapPropsToState = state => ({
  logMsg: state.chanState.consoleLastMsg,
  fullLog: state.chanState.consoleFullLog,
});

export default connect(mapPropsToState, { updateConsole, sendConsoleCommand })(RCONconsole);
