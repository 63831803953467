import { CONSOLE_UPDATE, CONSOLE_SEND } from './types';

export const updateConsole = (val) => dispatch => {
	dispatch({
		type: CONSOLE_UPDATE,
		payload: val
	})
};

export const sendConsoleCommand = (val) => dispatch => {
	dispatch({
        type: CONSOLE_SEND,
        payload: val
	})
};