import React from "react";
import ReactDOM from "react-dom";

import App from "./common/App/App";

import { Provider } from 'react-redux';
import store from "./common/Stores/store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root')
);
