import React, { Component } from "react";
import { hot } from "react-hot-loader";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import "react-notifications/lib/notifications.css";

import Login from "./Login";
import Dashboard from "./Dashboard";
import { NotificationContainer } from "react-notifications";
import Sockets from "./Components/sockets";

import { updateLoginState } from '../Actions/connStateAction';
import { connect } from 'react-redux';

const Auth = {
  Authenticated: false,
  authenticated(){
    this.Authenticated = true
  },
  signout(){
    this.Authenticated = false
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.Authenticated === true
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
          </Switch>
          <NotificationContainer />
          <Sockets />
        </div>
      </Router>
    );
  }
}

function mapPropsToState (state) {
  if(state.connState.loggedIn){
    Auth.authenticated();
  } else {
    Auth.signout();
  }
}

export default connect (mapPropsToState, {updateLoginState})(hot(module)(App));
