import React, { Component } from "react";
import { connect } from 'react-redux';
import { updateMonitorStats } from "../../Actions/monitorAction";

class Monitor extends Component {
  render() {
    return (
      <div className="monitor">{this.props.stats}</div>
    );
  }
}

const mapPropsToState = state =>({
  stats: state.chanState.monitorStats,
});

export default connect(mapPropsToState,{updateMonitorStats})(Monitor);
