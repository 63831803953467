import { CHAT_UPDATE, CHAT_SEND } from './types';

export const updateChat = (val) => dispatch => {
	dispatch({
		type: CHAT_UPDATE,
		payload: val
	})
};

export const sendChat = (val) => dispatch => {
	dispatch({
        type: CHAT_SEND,
        payload: val
	})
};