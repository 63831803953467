import { TRY_LOGIN } from './types';

export const attemptLogin = (u, p) => dispatch => {
	var creds = {
		user: u,
		pass: p,
	}

	dispatch({
		type: TRY_LOGIN,
		payload: creds
	})
};
