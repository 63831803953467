
import React, { Component } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { view } from "react-easy-state";
import { connect } from 'react-redux';
import { attemptLogin } from '../Actions/loginAction';

class Login extends Component {
  constructor(props) {
    super(props);
    this.checkConnState = this.checkConnState.bind(this);
    this.toggleRef = React.createRef();
  }
  state = {
    username: '', //"play.unitystation.org:3005"
    password: '', //"ap2_OEls"
    rememberMe: false
  }

  AttemptLogin() {
    this.props.attemptLogin(this.state.username, this.state.password);
    if(this.state.rememberMe === true){
      //save inputs
      localStorage.setItem("loginUser", this.state.username);
      localStorage.setItem("loginPass", this.state.password);
    }
  }

  handleChangeUsername(event) {
    this.setState({ username: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  checkConnState() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard/console");
    }
  }

  componentDidMount(){
    var savedRM = localStorage.getItem("rm");
    if(savedRM != null){
      this.toggleRef.current.checked = (savedRM == "true");
      this.setState({rememberMe: (savedRM == "true")});

      if((savedRM == "true")){
        this.setState({
          username: localStorage.getItem("loginUser"),
          password: localStorage.getItem("loginPass")
        });
      }
    }
  }

  toggleRememberMe() {
    this.setState({
        rememberMe: this.toggleRef.current.checked
    });

    if(!this.toggleRef.current.checked){
      localStorage.setItem("loginUser", '');
      localStorage.setItem("loginPass", '');
    }
    localStorage.setItem("rm", this.toggleRef.current.checked);
}

  render() {
    this.checkConnState();
    return (
      <div className="login-form">
        {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
        <style>{`

    `}
        </style>
        <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Form size="large">
              <Header as="h2" textAlign="center">
                Connect
              </Header>
              <Segment stacked className="login-inner-form">

                <Form.Input fluid icon="user" iconPosition="left" 
                placeholder="address:port" value={this.state.username} 
                onChange={::this.handleChangeUsername} />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="password"
                  type="password"
                  value={this.state.password}
                  onChange={::this.handleChangePassword}
              />

                <Button fluid size="large" onClick={this.AttemptLogin.bind(this)}>
                  Connect
                </Button>
                <div className="remember-me-group">
                  <input type="checkbox" className="remember-me-checkbox" id="rememberMe" ref={this.toggleRef} placeholder="Remember Me" onChange={::this.toggleRememberMe} />
                  <label className="remember-me-label" htmlFor="rememberMe">&nbsp; Remember me</label>
                </div>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapPropsToState = state => ({
  isLoggedIn: state.connState.loggedIn,
});

export default connect(mapPropsToState, { attemptLogin })(view(Login));
