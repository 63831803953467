import { MONITOR_UPDATE, INIT_MONITOR } from './types';

export const updateMonitorStats = (val) => dispatch => {
	dispatch({
		type: MONITOR_UPDATE,
		payload: val
	})
};

export const initMonitorChan = () => dispatch => {
	dispatch({
		type: INIT_MONITOR,
	})
};