
import React, { Component } from "react";
import { connect } from 'react-redux';
import socketStore from "../Stores/socketStore";
import { Input } from "semantic-ui-react";
import { updatePlayerList } from "../Actions/playersAction";

class RCONplayerlist extends Component {
  constructor(props){
    super(props);
    this.checkPlayerList = this.checkPlayerList.bind(this);
  }
  state={ players: [] }

  checkPlayerList(){
    if(this.props.playerList != this.state.players){
      this.setState({ players: this.props.playerList});
    }
  }

  render() {
    this.checkPlayerList();
    return this.state.players.map(player => <div > {player.playerName} {"-- SteamID:"} {player.steamID} {"Job:"}  {player.job}  </div>);
  }
}

const mapPropsToState = state => ({
  playerList: state.chanState.playerList, 
});

export default connect (mapPropsToState, { updatePlayerList })(RCONplayerlist);
