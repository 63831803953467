import {
	MONITOR_UPDATE,
	CONSOLE_UPDATE,
	CONSOLE_SEND,
	CHAT_UPDATE,
	CHAT_SEND,
	PLAYERLIST_UPDATE
} from '../../Actions/types';

const initialState = {
	monitorStats: '...',
	consoleLastMsg: '',
	consoleCommand: '',
	chatLastMsg: '',
	chatSend: '',
	consoleFullLog: '',
	chatFullLog: '',
	playerList: []
}

export default function (state = initialState, action) {
	switch (action.type) {

		case PLAYERLIST_UPDATE:
		return{
			...state,
			playerList: JSON.parse(action.payload).players,
		};

		case CHAT_SEND:
			return {
				...state,
				chatSend: action.payload,
			}

		case CHAT_UPDATE:
			return {
				...state,
				chatLastMsg: action.payload,
				chatFullLog: state.chatFullLog + action.payload,
			}

		case CONSOLE_UPDATE:
			return {
				...state,
				consoleLastMsg: action.payload,
				consoleFullLog: state.consoleFullLog + action.payload,
			};

		case CONSOLE_SEND:
			return {
				...state,
				consoleCommand: action.payload,
			};

		case MONITOR_UPDATE:
			return {
				...state,
				monitorStats: action.payload,
			};

		default:
			return state;
	}
}

