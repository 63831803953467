import { store } from "react-easy-state";
import Sockette from "sockette";

const socketStore = store({
  generate: (channel, params) => new Sockette(`ws://${socketStore.password}@${socketStore.username}/${channel}`, params),
  username: '', 
  password: '', 
  statusSocket: null,
});

export default socketStore;
