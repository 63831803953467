import { TRY_LOGIN, LOGIN_STATE } from '../../Actions/types';

const initialState = {
	loginUsername: '',
	loginPass: '',
	loggedIn: false
}

export default function(state = initialState, action){
	switch(action.type){
		case TRY_LOGIN:
		return {
			...state,
			loginUsername: action.payload.user,
			loginPass: action.payload.pass
		};

		case LOGIN_STATE:
		return {...state, loggedIn: action.payload};
		
		default:
		return state;
	}
}

